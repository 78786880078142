import * as Router from "vue-router"
import Home from "../components/Home";
import Gallerie from "../components/Gallerie";
import Game from "../components/Game";
import RespondToInvitation from "../components/RespondToInvitation";
import Invitation from "../components/Invitation";
import GetInvitation from "../components/GetInvitation";
import Store from "../store/store"
import Temoignage from "../components/Temoignage";
import MenuPage from "../components/MenuPage";
import Invisible from "@/components/Invisible";

function invitationGuard(to, from, next) {
    Store.commit("SetNextUrl",to.path);
    if (Store.state.invitation != null) {
        next();
    } else {
        next('/GetInvitation');
    }
}


const router = Router.createRouter({
    history: Router.createWebHistory(),
    routes: [
        {
            path:"/",
            name:"home",
            component:Home,
        },
        {
            path:"/gallerie",
            name:"gallerie",
            component:Gallerie,
        },
        {
            path:"/game/:type",
            name:"game",
            component:Game,
            beforeEnter:invitationGuard,
        },
        {
            path:"/respond_to_invitation",
            name:"respond_to_invitation",
            component:RespondToInvitation,
            beforeEnter:invitationGuard
        },
        {
            path:"/invitation",
            name:"invitation",
            component:Invitation,
            beforeEnter:invitationGuard
        },
        {
            path:"/invitation/:slug/:print?",
            name:"invitation-with-slug",
            component:Invitation,
        },
        {
            path:"/GetInvitation",
            name:"GetInvitation",
            component:GetInvitation,
        },
        {
            path:"/laisser_temoignage",
            name:"laisser_temoignage",
            component:Temoignage,
            beforeEnter:invitationGuard

        },
        {
            path:"/menu/:ceremonie_slug?",
            name:"mneu",
            component:MenuPage,
        },
        {
            path:"/invisible",
            name:"invisible",
            component:Invisible,
        },
        {
            path:"/np-admin",
            name:"np-admin",
            // eslint-disable-next-line no-unused-vars
            beforeEnter(to, from, next) {
                window.location.href = "https://panel.nice-planner.com";
            }
        }
    ]
});



router.beforeEach((to, from, next) => {
    if (!['invisible','np-admin'].includes(to.name) ){
        if (Store.state.websiteIsVisible === 1) {
            next();
        } else {
            next('/invisible');
        }
    }else{
        next();
    }

})

export default router
