<template>
    <transition name="scale-in">
        <div class="popup" v-if="show">
            <div class="popup-wrapper">
                <div class="card shadow px-3 py-2" style="max-width: 500px">
                    <div class="card-body text-center">
                        <h4 style="min-width: 200px" >{{message}}</h4>
                        <div class="row" style="margin: 20px 0px;">
                            <div class="submit-area d-flex justify-content-center mt-3">
                                <button @click="hidePopup" type="submit" class="theme-btn-s4 d-flex align-items-center">
                                    FERMER !
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "AlertComponent",
    computed:{
        show:{
            get:function () {
                return this.$store.state.popup.show;
            }
        },message:{
            get:function () {
                return this.$store.state.popup.message;
            }
        }
    },
    methods:{
        hidePopup:function () {
            this.$store.commit("hidePopup")
        }
    }
}
</script>

<style scoped>
    .popup{
        position: fixed;
        z-index: 999999;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100Vw;
        height: 100vh;
        background: #cdcdcd70;
    }

    /* scalle in animation */
    .scale-in-enter,
    .scale-in-leave-to{
        transform: scale(0.88);
        opacity: 0;
    }

    .scale-in-enter-active,
    .scale-in-leave-active{
        transition:  transform 0.3s, opacity 0.5s ;
    }

    /*.v-enter-active,*/
    /*.v-leave-active {*/
    /*    transition: opacity 0.5s ease;*/
    /*}*/

    /*.v-enter-from,*/
    /*.v-leave-to {*/
    /*    opacity: 0;*/
    /*}*/
</style>
