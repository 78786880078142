<template>
    <Header></Header>
    <!-- start wpo-page-title -->
    <section class="wpo-page-title">
        <div class="container">
            <div class="row">
                <div class="col col-xs-12">
                    <div class="wpo-breadcumb-wrap">
<!--                        <h2>Galerie</h2>-->
                    </div>
                </div>
            </div> <!-- end row -->
        </div> <!-- end container -->
    </section>
    <section class="wpo-event-section " id="event" style="padding: 50px 0px 0px 0px" v-if="categories != ''">
        <div class="container" >
            <div class="row mac-select-wrapper justify-content-center px-2">
                <div :class="(selected_gallerie === categorie.i ? 'mac-selected':'')+' col-6 col-md-4 col-lg-3 col-xl-2 p-3 text-center my-2'"  style="cursor:pointer;" v-for="(categorie,key) in categories.map((c,i)=>({c,i})).filter((c)=>c.c.photos.length > 0)" v-bind:key="key">
                    <div style="color: #8b96ab !important;height: 100%"  @click="selectGal(categorie.i)">
                        <h6 style="font-family: Parisienne, cursive;margin: 0px;border: 0px" class="mac-cap-first-letter">{{(categories.length == categorie.i+1?'':'Photos ')+categorie.c.nom}}</h6>
                    </div>
                </div>
            </div>
        </div> <!-- end container -->
    </section>
    <!-- end page-title -->
    <section v-if="categories != ''">
        <sub-gallerie :images="getImagesSrc(categories[selected_gallerie])" :title="(categories.length === selected_gallerie+1?'':'Photos ')+categories[selected_gallerie].nom" :intro="categories[selected_gallerie].nom"></sub-gallerie>
    </section>

    <section v-if="categories == ''" class="d-flex p-5 justify-content-center">
        <h1><i class="fa fa-spinner fa-spin"></i></h1>
    </section>
    <Footer></Footer>
</template>

<script>
import Header from "./SubComponents/Header";
import Footer from "./SubComponents/Footer";
import SubGallerie from "./SubComponents/Gallerie";
// import {defineAsyncComponent} from "vue";
export default {
    name: "Gallerie-component",
    components: { Header,Footer,SubGallerie},
    data:function () {
        return {
            images:[
            ],
            categories:'',
            selected_gallerie:0,
        }
    },
    methods:{
      getImagesSrc:function (categorie) {
          let images = [];
          for (let i=0;i<categorie.photos.length;i++){
              images.push({
                  src: categorie.photos[i].nom,
                  thumbnail: categorie.photos[i].nom,
                  download:true,
                  controls:true,
                  thumbnailWidth:"200",
                  description: categorie.nom,
              })
          }
          return images
      },
        selectGal:function (i){
            window.scrollTo(0,0)
            this.selected_gallerie = i
        }
    },
    mounted() {
        this.axios.get("/galerie/"+this.$weddingSlug).then((response) => {
            let images = response.data.data;
            this.axios.get("/categories/"+this.$weddingSlug).then((response) => {
                this.categories = response.data.data
                this.categories.push({
                    nom:"Toutes les photos",
                    photos:images
                })
            })

        })

    }

}
</script>

<style scoped>
.mac-back{
    background-color: rgba(134, 160, 182, 0.7);
    width: 45px;
    height: 45px;
    line-height: 45px;
    border: 2px solid #86a0b6;
    border-radius: 45px;
    text-align: center;
    transition: all 0.2s;
    color:white;
    transform:rotate(-90deg);
}
.mac-select-wrapper span{
    /*border:2px solid #86a0b6;*/
    margin:10px;
    padding: 15px 30px;
    transition: 0.2s;
}
.mac-select-wrapper span:hover{
    background-color: #86a0b6;
    color: white !important;
    transition: 0.2s;
}
.mac-selected h6{
    color: white !important;
}
.mac-selected {
    background-color: #86a0b6;

}
</style>
