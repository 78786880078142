<template>
  <section class="static-hero-s3" v-if="show">
    <div class="hero-container">
      <div class="hero-inner">
        <div class="container">
          <div class="row justify-content-center d-flex flex-column align-items-center">
            <a href="https://nice-planner.com" style="width: 170px">
              <img src="@/assets/images/logo.png" alt="Logo nice-planner" style="width: 170px!important;margin-bottom: 50px">
            </a>
            <div class="col-lg-6 d-flex justify-content-center align-items-start" style="height: 60vh;font-family:Parisienne, cursive;color: #002642;font-size: 40px;text-align: center">
              Bienvenue <br> sur <br> Nice-Planner
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section v-if="this.$store.state.websiteIsVisible == 0 && !show" style="font-size: 50px;width: 100%;height: 100vh;display:flex;justify-content: center;align-items: center">
    <i class="fa fa-spinner fa-spin"></i>
  </section>
</template>

<script>
  export default {
    name: "Invisible-component",
    data:function(){
      return {
        show:this.$store.state.websiteIsVisible === 2,
        debloacked:false,
      }
    },
    // computed:{
    //   showMessage: {
    //     get () {
    //       return this.$store.state.websiteIsVisible === 2
    //     },
    //     set () {
    //
    //     }
    //   }
    // }
    mounted() {
      setInterval(()=>{
        this.show = this.$store.state.websiteIsVisible === 2
      },500)
    }
  }
</script>

<style scoped>
  /* Hide scrollbar for Chrome, Safari and Opera */
  body::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
</style>
