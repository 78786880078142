import {createStore} from "vuex"
// import axios from "axios";
// import router from "@/routes/routes";

const state = {
    showModal: true,
    invitation : null,
    nextUrl:"",
    temoignage:null,
    showSideBar:false,
    invite_accompagner:false,
    nomInviteAccompagner:false,
    reposnseInvitation:'',
    groom:'',
    brode:'',
    printAndQuit:false,
    popup:{
        show:false,
        message:''
    },
    // showMenuPopUp:false,
    showCagnottePopUp:false,
    cagnotte:false,
    websiteIsVisible:0
}
const mutations = {
    MODAL: (state)=>{
        state.showModal = !state.showModal;
    },
    setInvitation:(state,invitation)=>{
        state.invitation = invitation
        localStorage.setItem('invite', JSON.stringify({
            invitation:invitation
        }))
        // state.temoignage = invitation.temoignages?.[0]
    },
    toggleShowMenuPopUp:(state)=>{
        state.showMenuPopUp = !state.showMenuPopUp;
    },
    setPrintAndQuit:(state,val)=>{
        state.printAndQuit = val
    },
    recoverBrowserSave:function (){
        let invitation = JSON.parse(window.localStorage.getItem("invite"));
        if (invitation != null){
            this.state.invitation = invitation.invitation
        }
    },
    SetNextUrl:(state,url)=>{
        let next = window.localStorage.getItem("next_url")
        if (next != null && url === "/GetInvitation"){
            state.nextUrl = next
        }else{
            state.nextUrl = url
        }
        localStorage.setItem('next_url',state.nextUrl)
    },
    setTemoignage:(state,temoignage)=>{
        state.temoignage = temoignage
    },
    toggleSidebar:(state)=>{
        state.showSideBar = !state.showSideBar;
    },
    hideSidebar:(state)=>{
        state.showSideBar = !state.showSideBar;
    },
    setInvitationSettings:(state,data)=>{
        state.invite_accompagner = (data.step2 === "1");
        state.nomInviteAccompagner = (data.step3 === "Oui")
    },
    setReponseInvitation: (state,data)=>{
        state.reposnseInvitation = data
    },
    setGroomAndBride: (state,data)=>{
        state.groom = data.groom
        state.bride = data.bride
    },
    hidePopup:function (state){
        state.popup.show = false;
    },
    setPopupMessage:function (state,message){
        state.popup.message = message;
    },
    showPopup:(state,data)=>{
        state.popup.show = true;
        state.popup.message = data;
    },
    setIviteAccompagner:(state,data)=>{
        state.invite_accompagner = data
    },
    setNomIviteAccompagner:(state,data)=>{
        state.nomInviteAccompagner = data
    },
    toggleCagnottePopUp:function (state) {
        state.showCagnottePopUp = !state.showCagnottePopUp
    },
    setCagnotte:function (state, data) {
        state.cagnotte = data
    },
}


let Store = createStore({
    state:state,
    mutations:mutations,
    getters:{},
    actions:{},
    strict: false
})

export default Store
