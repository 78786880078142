<template>
    <div class="page-wrapper">
        <Header></Header>
        <!-- start of hero -->
        <section class="wpo-hero-slider wpo-hero-style-3">
            <div class="wedding-announcement">
                <div class="couple-text">
                    <h2 class="slideInUp" data-wow-duration="2s" style="font-family: Parisienne, cursive;font-weight: bold;text-shadow: 2px 4px 3px #565656;">{{ bride }} <span v-if="bride === ''"><i class="fa fa-spinner fa-spin"></i></span> <span v-else> & </span> {{ groom }}</h2>
                    <p class="slideInUp mac-cap-first-letter" data-wow-duration="1.8s" style="font-family: Parisienne, cursive;font-weight: bold;">Se disent oui le {{ endDate.getUTCDate() }}, {{endDate.toLocaleString('fr-FR', { month: 'long' })}} {{endDate.getFullYear()}} au {{lieu}}</p>
                    <!-- start wpo-wedding-date -->
                    <div class="wpo-wedding-date slideInUp">
                        <div class="clock-grids">
                            <div id="clock">
                                <div class="box">
                                    <div>
                                        <div class="time" id="counter_days">{{ days }}</div>
                                        <span>Jours</span></div>
                                </div>
                                <div class="box">
                                    <div>
                                        <div class="time" id="counter_hours">{{ hour }}</div>
                                        <span>Heures</span></div>
                                </div>
                                <div class="box">
                                    <div>
                                        <div class="time" id="counter_minutes">{{ min }}</div>
                                        <span>Minutes</span></div>
                                </div>
                                <div class="box">
                                    <div>
                                        <div class="time" id="counter_sec">{{ sec }}</div>
                                        <span>Secondes</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end wpo-wedding-date -->
                </div>
            </div>

            <swiper :navigation="true"  :grabCursor="true"
                    :effect="'creative'"
                    :creativeEffect="{
                          prev: {
                            shadow: true,
                            translate: ['-20%', 0, -1],
                          },
                          next: {
                            translate: ['100%', 0, 0],
                          },
                        }"
                    :modules="modules"
                    :autoplay="true"
                    :speed="1500"
                    :delay="4000"
                    class="mySwiper3"
                    :rewind="true" >
                <swiper-slide v-for="(image,key) in images.position1.photos" v-bind:key="key" class="swiper-slide-main" :style="'background-repeat:no-repeat;background-size:cover;width:100%;height:100%;background-image: url('+image.nom+')'"></swiper-slide>
            </swiper>
        </section>
        <!-- end of hero slider -->
        <!-- start wpo-event-section -->
        <section class="wpo-event-section mt-5">
            <div class="container">
                <div class="row">
                    <div class="wpo-section-title-s2">
                        <div class="section-title-simg">
                            <img :src="this.$macBaseUrl+'/assets/images/section-title2.png'" alt="" loading="lazy">
                        </div>
                        <h2 style="font-family: Parisienne, cursive;text-transform: capitalize;font-weight: bold">à propos {{formatName(bride)}} et {{formatName(groom)}}</h2>
                        <div class="section-title-img">
                            <div class="round-ball"></div>
                        </div>
                    </div>
                </div>
                <div class="wpo-event-wrap">
                    <div class="row d-flex justify-content-center">
                        <p class="col-12 px-4 col-md-12" style="text-align: justify">
                            {{history}}
                        </p>
                    </div>
                </div>

            </div> <!-- end container -->
        </section>
        <!-- end wpo-event-section -->
        <!-- start couple-section -->
        <section class="couple-section" id="couple">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col col-xs-12">
                        <div class="couple-area clearfix">
                            <div class="text-grid bride mac-a-propos-conjoint mac-3" >
                                <div class="couple-img " style="margin-left: 0px">
                                    <img :src="images.position2?.photos?.[0]?.nom" :alt="'photo de'+bride">
                                </div>
                                <h3 style="font-family: Parisienne, cursive;text-transform: capitalize;text-align: left">{{ bride }} à propos {{formatName(groom)}}</h3>
                                <div style="text-align: left;overflow-wrap: anywhere" class="mac-j">
                                    <div class="mac-rond" style="float: right; border-radius: 0px 0px 0px 250px;"></div>
                                    {{toMyHusband}}
                                </div>
                            </div>
                            <div class="middle-couple-pic mac-1" style="display: flex">
                                <img style="margin: auto;height: 90%" :src="images.position3?.photos?.[0]?.nom" :alt="'photo de '+groom+' et '+bride">
                                <div class="frame-img mac-2"><img :src="this.$macBaseUrl+'/assets/images/couple/shape.png'" alt=""></div>
                            </div>
                            <div class="text-grid groom mac-a-propos-conjoint mac-3" >
                                <div class="couple-img" style="margin: 0px 0px 20px auto;">
                                    <img :src="images.position4?.photos?.[0]?.nom" :alt="'photo de'+groom">
                                </div>
                                <h3 style="font-family: Parisienne, cursive;text-transform: capitalize;text-align: right">{{ groom }} à propos {{formatName(bride)}}</h3>
                                <div style="text-align: right;overflow-wrap: anywhere" class="mac-j">
                                    <div class="mac-rond " style="float: left; border-radius: 0px 0px 250px 0px;"></div>
                                    {{ToMyWife}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- end container -->
        </section>
        <!-- end couple-section -->
        <!-- start wpo-event-section -->
        <section class="wpo-event-section " id="event">
            <div class="container">
                <div class="row">
                    <div class="wpo-section-title-s2">
                        <div class="section-title-simg">
                            <img :src="this.$macBaseUrl+'/assets/images/section-title2.png'" alt="" loading="lazy">
                        </div>
                        <h2 style="font-family: Parisienne, cursive;text-transform: capitalize;font-weight: bold">Les différentes cérémonies</h2>
                        <div class="section-title-img">
                            <div class="round-ball"></div>
                        </div>
                    </div>
                </div>
                <div class="wpo-event-wrap">
                    <div class="row">
                        <div class="col col-lg-4 col-md-6 col-12" v-for="(ceremonie,key) in ceremonies" :key="key" >
                            <div style="color: #8b96ab !important;height: 100%">
                                <div class="wpo-event-item" style="height: 100%">
                                    <div class="wpo-event-text" style="height: 100%">
                                        <h2 style="font-family: Parisienne, cursive;" class="mac-cap-first-letter">{{ceremonie?.nom}}</h2>
                                        <ul>
                                            <li v-if="ceremonie?.date != null">
                                                Date: {{'le '+new Date(ceremonie.date).getUTCDate()+' '+new Date(ceremonie.date).toLocaleString('fr-FR', { month: 'long' })+' '+new Date(ceremonie.date).getFullYear()+' à '+new Date(ceremonie.date).getHours()+':'+(new Date(ceremonie.date).getMinutes() > 9 ? new Date(ceremonie.date).getMinutes() : "0"+(new Date(ceremonie.date).getMinutes()))}}
                                            </li>
                                            <li v-if="ceremonie?.lieu != null">Lieu : {{ ceremonie.lieu }}</li>
                                            <li v-if="ceremonie?.description != null">Description : {{ ceremonie.description }}</li>

                                            <li v-if="ceremonie.coordonnees != null">
                                                <a class="popup-gmaps" target="_blank" :href="'https://www.google.com/maps/search/?api=1&query='+encodeURI(ceremonie.coordonnees)">
                                                    Localisation
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="event-shape-1">
                                        <img :src="this.$macBaseUrl+'/assets/images/event-shape-1.png'" alt="" loading="lazy">
                                    </div>
                                    <div class="event-shape-2">
                                        <img :src="this.$macBaseUrl+'/assets/images/event-shape-2.png'" alt="" loading="lazy">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div> <!-- end container -->
        </section>
        <!-- end wpo-event-section -->
        <section class="wpo-service-section-s2 section-padding">
            <div class="container">
                <div class="row">
                    <div class="wpo-section-title">
                        <h2 style="font-family: Parisienne, cursive;text-transform: capitalize;font-weight: bold;color:orange!important;">À votre attention !!!</h2>
                        <div class="section-title-img">
                            <img :src="this.$macBaseUrl+'/assets/images/section-title.png'" alt="">
                        </div>
                    </div>
                </div>
                <div class="wpo-service-wrap">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0">
                            <div class="wpo-service-item" style="height: 100%">
                                <div class="wpo-service-text"  style="height: 100%;position: relative">
                                    <div class="s-icon">
                                      <i class="fa fa-edit" style="font-size: 180%;color: #86a0b6"></i>
                                    </div>
                                    <router-link to="/respond_to_invitation" style="font-family: Parisienne, cursive;">Repondez à votre invitation</router-link>
                                    <p style="padding-bottom: 20px">Repondez à votre invitation en cliquant sur le bouton ci dessous</p>
                                    <div class="d-flex justify-content-center col-12 mac-mgg-1">
                                        <div class="submit-area d-flex justify-content-center mt-3">
                                            <router-link to="/respond_to_invitation" type="submit" style="font-size: 13px;color:white" class="theme-btn-s4 d-flex align-items-center">
                                                Repondre a mon invitation
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0"  v-if="this.$store.state.cagnotte && this.$store.state.invitation != null">
                            <div class="wpo-service-item"  style="height: 100%">
                                <div class="wpo-service-text"  style="height: 100%;position: relative">
                                    <div class="s-icon">
                                        <i class="fa fa-gift " style="font-size: 225%;color: #86a0b6"></i>
                                    </div>
                                    <a href="#" @click="this.$store.commit('toggleCagnottePopUp')" style="font-family: Parisienne, cursive;">Cadeau mariage</a>
                                    <p style="text-align: justify;padding-bottom: 20px">{{ texteCagnotte }}</p>
                                    <div class="d-flex mt-3 flex-column justify-content-center align-items-center mac-mgg-1" @click="this.$store.commit('toggleCagnottePopUp')">
                                        <button class="theme-btn-s4"  style="font-size: 13px">
                                            Offrir un cadeau
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0"  v-if="questionnaires.length > 0" >
                            <div class="wpo-service-item"  style="height: 100%">
                                <div class="wpo-service-text"  style="height: 100%;position: relative">
                                    <div class="s-icon">
                                        <span style="font-size: 225%;font-weight:bold;color: #86a0b6">?</span>
                                    </div>
                                    <a href="#" style="font-family: Parisienne, cursive;">Connaissez-vous bien les futurs epoux ?</a>
                                    <p style="padding-bottom: 20px">Participez à notre quizz pour le savoir et gagner un cadeau.</p>
                                    <div class="d-flex flex-column justify-content-center align-items-center mt-3 mac-mgg-1">
                                        <button @click="showQuizzMenu = !showQuizzMenu" class="theme-btn-s4"  style="font-size: 13px">Jouer</button>
                                        <transition mode="fade-y">
                                            <div v-if="showQuizzMenu" class="nav navbar-nav mb-2 mb-lg-0 mt-3">
                                                <div class="sub-menu mac-game-menu" style="">
                                                    <div v-for="(questionnaire,questionnaireKey) in questionnaires" v-bind:key="questionnaireKey">
                                                        <router-link @click="this.$store.commit('hideSidebar')" style="font-size: 13px" :to="'/game/'+questionnaire.slug" :class="this.$route.name == 'game' ?'active':''">{{questionnaire.nom}}</router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- end container -->
        </section>
<!--        &lt;!&ndash; end wpo-cta-section &ndash;&gt;-->
<!--        <section class="wpo-event-section" >-->
<!--            <div class="container">-->
<!--                <div class="row">-->
<!--                    <div class="wpo-section-title-s2">-->
<!--                        <div class="section-title-simg">-->
<!--                            <img :src="this.$macBaseUrl+'/assets/images/section-title2.png'" alt="" loading="lazy">-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <section class="wpo-banner-section my-5">-->
<!--                    <h4 style="font-family: Parisienne, cursive;text-transform: capitalize">Repondez a votre invitation en cliquant sur ce boutton</h4>-->
<!--                    <div class="col-8 d-flex justify-content-center col-12">-->
<!--                        <div class="submit-area d-flex justify-content-center mt-3">-->
<!--                            <router-link to="/respond_to_invitation" type="submit" class="theme-btn-s4 d-flex align-items-center">-->
<!--                                Repondre a mon invitation-->
<!--                            </router-link>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </section>-->

<!--            </div> &lt;!&ndash; end container &ndash;&gt;-->
<!--        </section>-->

<!--        &lt;!&ndash; end wpo-video-section&ndash;&gt;-->
<!--        <div v-if="this.$store.state.cagnotte && this.$store.state.invitation != null" class="wpo-cta-section-s3 mb-5" style="background-repeat:no-repeat;background-size:cover;background-position:center;background-image:url('./assets/images/gift-bg.jpg')">-->
<!--            <div class="conatiner-fluid mac-gift-bg">-->
<!--                <p data-v-f2b6376c="" style="font-family: Parisienne, cursive; font-weight: bold; font-size: 30px;color:white;text-align: center;padding: 0px 10%">-->
<!--                    {{ texteCagnotte }}-->
<!--                </p>-->
<!--                <div class="d-flex flex-column justify-content-center align-items-center" @click="this.$store.commit('toggleCagnottePopUp')">-->
<!--                    <button class="theme-btn-s2">-->
<!--                        Participer à la cagnotte-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        &lt;!&ndash; start wpo-cta-section &ndash;&gt;-->

<!--        <div v-if="questionnaires.length > 0" class="wpo-cta-section-s3 mt-5" :style="'background-repeat:no-repeat;background-size:cover;background-position:center;background-image:url('+images.position5?.photos?.[0]?.nom+')'">-->
<!--            <div class="conatiner-fluid">-->
<!--                <div class="wpo-cta-item">-->
<!--                    <h4 class="text-white" style="font-size: 60px!important;font-family: Parisienne, cursive;text-transform: capitalize;font-weight: bold">Vous connaissez bien les futurs mariees ?</h4>-->
<!--                    <p style="font-family: Parisienne, cursive;font-weight: bold;font-size: 30px ">Jouez a notre quizz pour le savoir et gagner un cadeau</p>-->
<!--                    <div class="d-flex flex-column justify-content-center align-items-center">-->
<!--                        <button @click="showQuizzMenu = !showQuizzMenu" class="theme-btn-s2">Jouer</button>-->
<!--                        <transition mode="fade-y">-->
<!--                            <div v-if="showQuizzMenu" class="nav navbar-nav mb-2 mb-lg-0 mt-3">-->
<!--                                <div class="sub-menu mac-game-menu" style="">-->
<!--                                    <div v-for="questionnaire in questionnaires">-->
<!--                                        <router-link @click="this.$store.commit('hideSidebar')" :to="'/game/'+questionnaire.slug" :class="this.$route.name == 'game' ?'active':''">{{questionnaire.nom}}</router-link>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </transition>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->



        <!-- start wpo-testimonials-section -->
        <section class="wpo-testimonials-section section-padding">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-12">
                        <div class="wpo-testimonials-img">
                            <img :src="images.position6?.photos?.[0]?.nom" alt="" loading="lazy">
                            <div class="wpo-testimonials-img-shape">
                                <img :src="this.$macBaseUrl+'/assets/images/testimonial/shape.png'" alt="" loading="lazy">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 offset-lg-1 col-12">
                        <div class="wpo-testimonials-wrap">
                            <h2 style="font-family: Parisienne, cursive;text-transform: capitalize;font-weight: bold;font-size: 47px">Temoignages</h2>
                            <swiper :modules="modules"
                                    :autoplay="{delay:10000,}"
                                    :rewind="true"
                                    :pagination="true"
                                    :grabCursor="true"
                                    :disableOnInteraction="true"
                                    class="mySwiper"
                                    @mouseover="afficher('1')"
                                    @mouseleave="startSlide()">
                                <swiper-slide class="wpo-testimonials-active" v-for="(temoignage,key) in temoignages" :key="key">
                                    <div class="wpo-testimonials-item">
                                        <p style="text-align: left;font-size: 16px!important;">{{ temoignage.texte }}</p>
                                        <div class="wpo-testimonial-info pb-1">
                                            <div class="wpo-testimonial-info-img">
    <!--                                            <img :src="this.$macBaseUrl+'/assets/images/testimonial/img-1.jpg'" alt="" loading="lazy">-->
                                            </div>
                                            <div class="wpo-testimonial-info-text">
                                                <h5>{{temoignage.nom}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </div>
                    </div>
                </div>

            </div> <!-- end container -->

            <div class="wpo-testimonials-shape">
                <img :src="this.$macBaseUrl+'/assets/images/testimonial/shape2.png'" alt="" loading="lazy">
            </div>
        </section>
        <!-- end wpo-testimonials-section -->

        <div v-if="gallerieImages != ''">
            <SubGallerie :images="gallerieImages" ></SubGallerie>
        </div>
        <div style="text-align: center" v-if="gallerieImages === ''">
            <h1><i class="fa fa-spinner fa-spin"></i></h1>
        </div>

        <Footer></Footer>

    </div>
</template>
<script>
import Header from "./SubComponents/HeaderTransparent";
import Footer from "./SubComponents/Footer";
import { defineAsyncComponent } from 'vue'
import { Swiper, SwiperSlide ,useSwiper} from 'swiper/vue';
import 'swiper/css';

import 'swiper/css/effect-creative';
import { Navigation, EffectCreative, Autoplay, Pagination } from 'swiper';

export default {
    name: "Home-component",
    components: {SubGallerie:defineAsyncComponent(() =>
            import('./SubComponents/Gallerie')
        )
        , Header,Footer,Swiper,SwiperSlide},
    data(){
        return{
            now : new Date(),
            timer : null,
            counter:{
                days:0,
                hours:0,
                mins:0,
                secs:0
            },
            groom:"",
            bride:"",
            endDate:new Date("2023-05-19 21:00:00"),
            ceremonies:[
                {
                    nom:"La Dote",
                    date:"2023-05-19 21:00:00",
                    lieu:"Village",
                    description:"il y aura beaucoup de bierre",
                    coordonnees:"",
                }
            ],
            temoignages:[
                {
                    nom:"Jhon",
                    texte:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                },
                {
                    nom:"Jhon2",
                    texte:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                }
            ],
            ToMyWife:"I love you my wife",
            toMyHusband:"I love you my husband",
            history:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            gallerieImages:'',
            images:{
                position1:'',
                position2:'',
                position3:'',
                position4:'',
                position5:'',
                position6:'',
            },
            questionnaires:'',
            showQuizzMenu:false,
            texteCagnotte:'',
            dateFermetureReponse:'',
            lieu:'',
        }
    },
    computed:{
        days(){
            let d = Math.trunc((this.endDate - this.now) /(1000 * 60 * 60 * 24));
            return d>9?d:'0'+d;
        },
        hour(){
            let h = Math.trunc((this.endDate - this.now) % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
            return h>9?h:'0'+h;
        },
        min(){
            let m = Math.trunc((this.endDate - this.now) / 1000 / 60) % 60;
            return m>9?m:'0'+m;
        },
        sec(){
            let s = Math.trunc((this.endDate - this.now)/1000) % 60
            return s>9?s:'0'+s;
        }
    },
    watch : {
        endDate : {
            immediate : true,
            handler(newVal){
                if(this.timer){
                    clearInterval(this.timer)
                }
                this.timer = setInterval(()=>{
                    this.now = new Date()
                    // if(this.negative)
                        // return
                    if(this.now > newVal){
                        this.now = newVal
                        // this.$emit('endTime')
                        clearInterval(this.timer)
                    }
                }, 1000)
            }
        }
    },
    beforeUnmount(){
        clearInterval(this.timer)
    },
    setup() {
        const swiper = useSwiper();
        return {
            swiper,
            modules: [Navigation,EffectCreative,Autoplay,Pagination],
        };
    },
    mounted() {
        this.axios.get("/mariage/"+this.$weddingSlug).then((response) => {
            this.groom = response.data.data.nom_monsieur;
            this.bride = response.data.data.nom_madame;
            this.ToMyWife = response.data.data.apropos_madame;
            this.toMyHusband = response.data.data.apropos_monsieur;
            this.history = response.data.data.histoire;
            this.endDate = new Date(response.data.data.date);
            this.ceremonies = response.data.data.ceremonies;
            this.temoignages = response.data.data.temoignages;
            // this.gallerieImages = response.data.data.gallerie;
            this.$store.commit("setCagnotte",response.data.data.cagnotte)
            this.texteCagnotte = response.data.data.texteCagnotte;
            this.lieu = response.data.data.ceremonies.find((c)=>c.IsDefaultCeremonie == true).lieu

        })
        this.axios.get("/galerie/"+this.$weddingSlug).then((response) => {
            this.gallerieImages = [];
            for (let i=0;i<response.data.data?.length;i++){
                if (i<10) this.gallerieImages.push({
                    src: response.data.data[i].nom,
                    thumbnail: response.data.data[i].nom,
                    download:true,
                    controls:true,
                    thumbnailHeight:"200",
                    thumbnailWidth:"200",
                    // srcSet: '/images/image001-640.jpg 640w,/images/image001-1280.jpg 1280w,/images/image001-1920.jpg 1920w',
                    description: 'Quelques photos',
                },)
            }
        })
        this.axios.get("/positions/"+this.$weddingSlug).then((response) => {
            this.images.position1 = response.data.data.find((p) => p.nom === "0")
            this.images.position2 = response.data.data.find((p) => p.nom === "1")
            this.images.position3 = response.data.data.find((p) => p.nom === "2")
            this.images.position4 = response.data.data.find((p) => p.nom === "3")
            this.images.position5 = response.data.data.find((p) => p.nom === "4")
            this.images.position6 = response.data.data.find((p) => p.nom === "5")
            this.$store.commit("setInvitationSettings",{step2:response.data.data.invite_accompagner,step3:response.data.data.nomInviteAccompagner})
        })
        this.axios.get("/questionaires/"+this.$weddingSlug).then((response) => {
            this.questionnaires = response.data.data;
        })
    },
    methods:{
        afficher:function (swiper) {
            console.log(swiper)
        },
        startSlide:function () {
            console.log(this.swiper)
        },
        formatName:function (name) {
            let first = name.split("")[0]
            let c = ['a','e','i','o','u','y','A','E','I','O','U','Y']
            if (c.includes(first)) return "d'"+name;
            else return "de "+name;
        }
    }

}
</script>

<style scoped>
#app {
    height: 100%;
}
html,
body {
    position: relative;
    height: 100%;
}

body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background:transparent;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
}
.swiper-slide-main:before{
    content: '';
    background-color: #00315f;
    opacity: 0.15;
    position: absolute;
    width:100%;
    height: 100%;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
    transition: opacity 0.5s ease;

}
</style>
