<template>
    <AlertComponent></AlertComponent>
    <cagnote-pop-up></cagnote-pop-up>
    <router-view :key="this.$route.fullPath"></router-view>
    <div v-if="showBtn && $store.state.websiteIsVisible === 1" @click="showScanPage()" title="scanner le menu" style="justify-content: center;align-items: center;display:flex;color:white;position: fixed;bottom: 70px;right: 20px;border:2px solid #002642;background-color:#002642;border-radius: 100px;padding:10px;z-index:999;cursor:pointer">
        <span class="me-2 text-white d-md-block d-none">Scanner le menu</span>
        <i class="fa fa-qrcode"></i>
    </div>
</template>

<script>
import AlertComponent from "./SubComponents/AlertComponent";
import CagnotePopUp from "./cagnotePopUp";
import axios from "axios";

export default {
    name: 'App',
    data:function(){
        return{
            showBtn:false,
            invite:null,
            route:null,
            forceHome:false,
            printandquit:false
        }
    },
    components: { CagnotePopUp, AlertComponent},
    created() {

    },
    methods:{
        showScanPage:function () {
            this.$router.push("/menu");
        },
        launch:function (url) {

          //retrive the invitation slug from the url and get the invitation from the server
          let slug = url.searchParams.get("invitation")
          if (slug){
            this.invite = this.axios.get("/detailInvite/"+slug).then((response)=>{
              if (this.invite != null){
                this.$store.commit("setInvitation",response.data?.data)
              }
            })

          }else{
            this.$store.commit("recoverBrowserSave")
          }

          //verify if the website is deactivated
          axios.get("/mariage/"+this.$weddingSlug).then((response) => {
            this.$store.state.websiteIsVisible = response.data?.data?.visible ? 1 : 2;

            this.$router.push(url.pathname);
            // this.$router.push("/")
          })

          this.$router.push(url.pathname);
        },
    },
    mounted(){
      //import external script files
      let theScript = document.createElement('script')
      theScript.setAttribute('src', this.$macBaseUrl+'/assets/js/jquery.min.js')
      document.head.appendChild(theScript)

      theScript = document.createElement('script')
      theScript.setAttribute('src', this.$macBaseUrl+'/assets/js/bootstrap.bundle.min.js')
      document.head.appendChild(theScript)

      theScript = document.createElement('script')
      theScript.setAttribute('src', this.$macBaseUrl+'/assets/js/modernizr.custom.js')
      document.head.appendChild(theScript)

      theScript = document.createElement('script')
      theScript.setAttribute('src', this.$macBaseUrl+'/assets/js/jquery.dlmenu.js')
      document.head.appendChild(theScript)

      theScript = document.createElement('script')
      theScript.setAttribute('src', this.$macBaseUrl+'/assets/js/jquery-plugin-collection.js')
      document.head.appendChild(theScript)

      // theScript = document.createElement('script')
      // theScript.setAttribute('src', this.$macBaseUrl+'/assets/js/script.js')
      // document.head.appendChild(theScript)

      theScript = document.createElement('script')
      theScript.setAttribute('src', 'https://i-pay.money/checkout.js')
      document.head.appendChild(theScript)





       if (this.$store != null){

           this.axios.get("/mariage/"+this.$weddingSlug).then((response) => {
               this.$store.commit("setCagnotte",response.data.data.cagnotte)
               document.title = response.data.data.nom_madame+" & "+response.data.data.nom_monsieur;
               var link = document.querySelector("link[rel~='icon']");
               if (!link) {
                   link = document.createElement('link');
                   link.rel = 'icon';
                   document.head.appendChild(link);
               }
               link.href = this.$macBaseUrl+"/"+response.data.data.image_qr_code;
               this.showBtn = response.data?.data?.button_qr_code
           });
       }
       this.launch((new URL(window.location.href)));

    }
}
</script>
<style>
@import "/src/assets/css/bootstrap.min.css";
@import "/src/assets/css/style.css";
@import "https://fonts.googleapis.com/css2?family=Parisienne&amp;display=swap";
@import "/src/assets/css/font-awesome.min.css";
@import "/src/assets/css/swiper.min.css";

/*@import "http://127.0.0.1:8000/assets/css/flaticon.css";*/
  /*@import "http://127.0.0.1:8000/assets/css/animate.css";*/
  /*@import "http://127.0.0.1:8000/assets/css/jquery.fancybox.css";*/
  /*@import "http://127.0.0.1:8000/assets/css/themify-icons.css";*/
  /*@import "http://127.0.0.1:8000/assets/css/magnific-popup.css";*/
  /*@import "http://127.0.0.1:8000/assets/css/owl.carousel.css";*/
  /*@import "http://127.0.0.1:8000/assets/css/owl.theme.css";*/
  /*@import "http://127.0.0.1:8000/assets/css/slick.css";*/
  /*@import "http://127.0.0.1:8000/assets/css/slick-theme.css";*/
  /*@import "http://127.0.0.1:8000/assets/css/nice-select.css";*/
  /*@import "http://127.0.0.1:8000/assets/css/owl.transitions.css";*/
  /*@import "http://127.0.0.1:8000/assets/css/odometer-theme-default.css";*/
  /*@import "http://127.0.0.1:8000/assets/css/auth-pages.css";*/
</style>

