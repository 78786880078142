<template>
    <Header></Header>
    <section class="wpo-contact-pg-section static-hero-s2" style="padding-top: 170px;background-color: #edf3f8">
        <div class="container">
            <div v-if="!loading" class="row">
                <div class="col col-lg-10 offset-lg-1">
                    <div class="wpo-contact-title">
                        <h2>LAISSER UN TÉMOIGNAGE</h2>
                    </div>
                    <div class="wpo-contact-form-area" style="padding-bottom: 10px!important;margin-bottom: 60px !important">
                        <div class="mac-response mac-response-choosed mb-3" v-if="this.$store.state.temoignage != null">Votre témoignage est {{this.$store.state.temoignage?.etatTemoignage}}</div>
                        <form method="post" class="contact-validation-active" novalidate="novalidate">
                            <div class="fullwidth">
                                <textarea v-model="temoignage" class="form-control" autofocus name="note" id="note" placeholder="Message..."></textarea>
                            </div>
                            <div class="submit-area d-flex justify-content-center" style="margin-bottom: 50px">
                                <button @click="sendTemoignage()" type="button" class="theme-btn-s4 d-flex">
                                    <span class="me-2">
                                        Envoyer <i v-if="sendingTemoignage" class="fa fa-spinner ms-1 fa-spin"></i>
                                    </span>
                                </button>

                            </div>
                        </form>
                    </div>
                </div>
<!--                <div class="col col-lg-10 offset-lg-1"  v-if="this.$store.state.temoignage != null">-->
<!--                    <div class="wpo-contact-title">-->
<!--                        <h2>VOTRE TÉMOIGNAGE</h2>-->
<!--                    </div>-->
<!--                    <div class="wpo-contact-form-area" style="padding-bottom: 10px!important;margin-bottom: 60px !important;" >-->
<!--                        <div class="mac-response mac-response-choosed mb-3">Votre témoignage est {{this.$store.state.temoignage.etatTemoignage}}</div>                        <div class="col-12 col-md-8 px-2" style="min-height: 150px">-->
<!--                            {{$store.state.temoignage.texte}}-->
<!--                        </div>-->
<!--                        <div class="submit-area d-flex justify-content-center" style="margin-bottom: 50px">-->
<!--                            <button @click="editTemoignage()" type="button" class="theme-btn-s4 d-flex">-->
<!--                                <span>Modifier mon temoignage</span>-->
<!--                            </button>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
                <div class="mac-flower-shape-1"><img src="/assets/images/slider/flower1.png" alt=""></div>
                <div class="mac-flower-shape-2"><img src="/assets/images/slider/flower2.png" alt=""></div>
            </div>
            <div class="row " v-if="loading">
                <h1 class="col-12 justify-content-center align-items-center d-flex"><i class="fa fa-spinner fa-spin"></i></h1>
            </div>
        </div> <!-- end container -->
    </section>
    <Footer></Footer>
</template>

<script>
import Footer from "./SubComponents/Footer";
import Header from "./SubComponents/Header";

export default {
    name: "Temoignage-component",
    components: {Footer, Header},
    data:function () {
        return {
            temoignage:"",
            sendingTemoignage:false,
            loading:true,
        }
    },
    methods:{
        sendTemoignage:function () {
            this.sendingTemoignage = true
            this.axios.post("/temoignage",{
                slug_invitation : this.$store.state.invitation.slug,
                temoignage : this.temoignage,
            }).then((response) => {
                let result = response.data;
                if(result.error != null) {
                    this.$store.commit("showPopup",result.error)
                }else{
                    if (result.success != null) this.$store.commit("showPopup",result.success)
                    this.$store.commit("setTemoignage",result.temoignage)
                    this.processing = false
                }
            }).catch(error => {
                if(error.response.status == 500){
                    this.$store.commit("showPopup","Une erreur s'est produite veuillez réessayer plus tard")
                }else{
                    this.$store.commit("showPopup",error.response.data.errors)
                }
            }).finally(() => this.sendingTemoignage = false);
        },
        editTemoignage:function () {
            this.temoignage = this.$store.state.temoignage.texte
            this.$store.commit("setTemoignage",null)
        }
    },mounted() {
        this.axios.get("/mariage/je-sais-pas/"+this.$store.state.invitation.slug).then((response) => {
            this.$store.commit("setTemoignage",response.data.data.monTemoignage)
            if ( this.$store.state.temoignage != null){
                this.temoignage = this.$store.state.temoignage.texte
            }
        }).finally(()=>this.loading = false)
    }

}
</script>

<style>
.mac-spin{
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: fit-content;
}
@keyframes spin {
    from {
        transform:rotate(360deg);
    }
    to {
        transform:rotate(0deg);
    }
}
</style>
