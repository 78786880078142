<template>
    <Header></Header>
    <section class="static-hero-s2" style="padding-top: 150px;height: auto;padding-bottom: 100px;min-height: 700px">
        <div class="hero-container">
            <transition-group name="fade-y" mode="out-in">
                <div v-if="!showScore" class="hero-inner">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <transition-group name="fade-y" mode="out-in">
                                <div class="row" v-if="!quizStarted && !showFinish" :key="1">
                                    <div class="wpo-section-title">
                                        <h2 v-if="nomQuestionaire!==''">{{ nomQuestionaire.toUpperCase() }}</h2>
                                        <h2 v-if="nomQuestionaire===''"><i class="fa fa-spinner fa-spin"></i></h2>
                                        <div class="section-title-img">
                                            <img src="/assets/images/section-title.png" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="quizStarted" :key="2">
                                    <div class="wpo-section-title">
                                        <h2>Question {{activeQuestion+1}} sur {{questions.length}}</h2>
                                        <div class="section-title-img">
                                            <img src="/assets/images/section-title.png" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="showFinish" :key="2">
                                    <div class="wpo-section-title">
                                        <h2>Vos reponses au quizz</h2>
                                        <div class="section-title-img">
                                            <img src="/assets/images/section-title.png" alt="">
                                        </div>
                                    </div>
                                </div>
                            </transition-group>
                            <div class="d-flex justify-content-center">
                                <div class="col-12 col-md-8 col-lg-6" style="min-height: 150px;">
                                    <transition-group name="fade-y"  mode="out-in">
                                        <div class="col-8 d-flex justify-content-center col-12"  v-if="!quizStarted && !showFinish" :key="1">
                                            <div class="submit-area d-flex justify-content-center mt-3" @click="startQuiz()">
                                                <button type="submit" class="theme-btn-s4 d-flex align-items-center">
                                                    Commencer le jeux
                                                </button>
                                            </div>
                                        </div>
                                        <div class="questions"  v-if="quizStarted" :key="2">
                                            <transition-group name="roll-in-left" mode="out-in">
                                                <template v-for="(item,key) in questions" :key="key+1">
                                                    <div class="card shadow" v-if="activeQuestion === key">
                                                        <div class="card-body text-center">
                                                            <h4>{{item.question}}</h4>
                                                            <div class="row" style="margin: 20px 0px">
                                                                <div class="col-12 p-2 col-md-6"   v-for="(response,responseKey) in item.responses" :key="responseKey" @click="chooseResponse(key,responseKey)">
                                                                    <div :class="'mac-response '+(item.choosedResponse.includes(responseKey)?'mac-response-choosed':'')">
                                                                        {{response.libelle}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </transition-group>
                                            <div class="submit-area d-flex justify-content-center mt-5 pt-1">
                                                <button type="submit" class="theme-btn-s4 d-flex align-items-center" @click="prevQuestion()" v-if="activeQuestion !== 0">
                                                    <i class="fi flaticon-left-arrow me-2"></i>
                                                    Question precedente
                                                </button>
                                                <button type="submit" class="theme-btn-s4 ms-3 d-flex align-items-center" @click="nextQuestion()" v-if="questions[activeQuestion].choosedResponse !== null">
                                                    {{(questions.length !== activeQuestion ) ? "Question suivante":"Voir toutes mes reponses"}}
                                                    <i class="fi flaticon-right-arrow-1 ms-2"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="questions"  v-if="showFinish" :key="3">
                                            <div class="card shadow">
                                                <div class="card-body text-center">
                                                    <div class="row" style="margin: 20px 0px">
                                                        <div class="col-12 d-flex align-items-center py-1" v-for="(question,questionKey) in questions" :key="questionKey">
                                                            <p>{{questionKey+1}}) {{question.question}}</p>
                                                            <div class="d-flex ms-auto flex-wrap justify-content-end align-items-center">
                                                                <div class=" mt-1 mac-response mac-response-choosed ms-1" v-for="(cResp,cREspKey) in question.choosedResponse" v-bind:key="cREspKey">
                                                                    {{question.responses[cResp].libelle}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="submit-area d-flex justify-content-center mt-5 pt-1">
                                                <button type="submit" class="theme-btn-s4 d-flex align-items-center" @click="returnToGame()">
                                                    <i class="fi flaticon-left-arrow me-2"></i>
                                                    Changer mes reponses
                                                </button>
                                                <button type="submit" class="theme-btn-s4 ms-3 d-flex align-items-center" @click="saveResponses()">
                                                    Envoyer mes reponses <i class="fa fa-spinner ms-2 fa-spin" v-if="sendingResponse"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </transition-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="showScore" class="hero-inner">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <transition name="fade-y" mode="out-in">
                                <div class="row">
                                    <div class="wpo-section-title" v-if="canShowScore">
                                        <h2>Votre Score est de :</h2>
                                        <span style="font-size: 100px;font-weight: bold">
                                            <vue3-autocounter style="font-size: 100px" ref='counter' :startAmount='0' :endAmount='score' :duration='score < 10 ? 1:2' />%
                                        </span>
                                        <div class="section-title-img">
                                            <img src="/assets/images/section-title.png" alt="">
                                        </div>
                                    </div>
                                    <div class="wpo-section-title">
                                        <h2>Vos reponses</h2>
                                    </div>
                                </div>
                            </transition>
                            <div class="d-flex justify-content-center">
                                <div class="col-12 col-md-8 col-lg-6" style="min-height: 150px;">
                                    <transition name="fade-y"  mode="out-in">
                                        <div class="questions">
                                            <div class="card shadow">
                                                <div class="card-body text-center">
                                                    <div class="row" style="margin: 20px 0px">
                                                        <div class="col-12 d-flex align-items-center py-1" v-for="(question,questionKey) in questions" :key="questionKey">
                                                            <p>{{questionKey+1}}) {{question.question}}</p>
                                                            <div class="d-flex ms-auto flex-wrap justify-content-end align-items-center">
                                                                <div  v-for="(response,rKey) in question.responses" v-bind:key="rKey">
                                                                    <div v-if="question.choosedResponse.includes(rKey)" class="mt-1 mac-response mac-response-choosed ms-1">
                                                                        {{ response.libelle}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="submit-area d-flex justify-content-center mt-5 pt-1">
                                                <button type="submit" class="theme-btn-s4 d-flex align-items-center" @click="restartGame()">
                                                    Changer Vos Reponses
                                                </button>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>
        <div class="flower-shape-1"><img src="/assets/images/slider/flower1.png" alt=""></div>
        <div class="flower-shape-2"><img src="/assets/images/slider/flower2.png" alt=""></div>
    </section>

    <Footer></Footer>
</template>

<script>
import Header from "./SubComponents/Header";
import Footer from "./SubComponents/Footer";
import Vue3autocounter from 'vue3-autocounter';
export default {
    name: "Game-component",
    components: {Footer, Header,'vue3-autocounter': Vue3autocounter},
    data: function () {
        return {
            showScore:false,
            quizStarted:false,
            canShowScore:false,
            activeQuestion:0,
            showFinish:false,
            nomQuestionaire:'',
            sendingResponse:false,
            questions : [
                {
                    question:"",
                    choosedResponse:[],
                    responses:[
                    ]
                }

            ],
            score:0
        };
    },
    methods: {
        startQuiz:function () {
            this.quizStarted = !this.quizStarted
        },
        nextQuestion:function (){
            if (this.questions[this.activeQuestion].choosedResponse.length == 0){
                this.$store.commit("showPopup","Veuillez choisir au moins une réponse")
            }else{
                if (this.questions.length >= this.activeQuestion+2 && this.questions[this.activeQuestion].choosedResponse !== null){
                    this.activeQuestion++
                }else{
                    if (this.questions.length === this.activeQuestion+1){
                        this.quizStarted = false
                        this.showFinish = true
                    }
                }
            }

        },
        prevQuestion:function (){
            if (this.activeQuestion > 0){
                this.activeQuestion--

            }
        },
        chooseResponse:function (question,response){
            if (!this.questions[question].choosedResponse.includes(response)){
                this.questions[question].choosedResponse.push (response)
            }else{
                let i = this.questions[question].choosedResponse.findIndex((r)=>r==response)
                this.questions[question].choosedResponse.splice(i,1)
            }

            // this.nextQuestion()
        },
        returnToGame:function () {
            this.showFinish = false;
            this.quizStarted = true;
        },
        saveResponses(){
            this.sendingResponse = true;
            let responses = [];
            for (let i = 0;i < this.questions.length;i++){
                let response=[];
                for (let j = 0;j < this.questions[i].choosedResponse.length;j++){
                    response.push(this.questions[i].responses[this.questions[i].choosedResponse[j]].slug)
                    // responses.push({
                    //     slug_question:this.questions[i].slug,
                    //     slug_reponse:this.questions[i].responses[this.questions[i].choosedResponse].slug,
                    // })
                }
                responses.push({
                    slug_question:this.questions[i].slug,
                    slug_reponse:response,
                })
            }
            this.axios.post("/sauvegarder_reponses",{
                slug_invitation:this.$store.state.invitation.slug,
                reponses:responses,
            }).then((response) => {
                let result = response.data;
                if(result.error != null) {
                    this.$store.commit("showPopup",result.error)
                }else{
                    if (result.success != null) {
                        this.$store.commit("showPopup", result.success);
                        this.score = result.score
                        this.showScore = true;
                    }
                }
              // eslint-disable-next-line no-unused-vars
            }).catch(error => {
                this.$store.commit("showPopup","Une erreur s'est produite veuillez réessayer plus tard")
            }).finally(() => this.sendingResponse = false)
        },
        restartGame:function () {
            this.showScore = false;
            this.quizStarted = true;
            this.activeQuestion = 0;
        }
    },
    mounted() {
        this.axios.get("/questionaires/je-sais-pas/"+this.$store.state.invitation.slug).then((response) => {
            let questionnaire = response.data.data.find((s)=> s.slug == this.$route.params.type )
            this.nomQuestionaire = questionnaire.nom;
            this.score = questionnaire.monAncienScore
            this.canShowScore = (questionnaire.afficherScore == "Oui");
            this.showScore = questionnaire.dejaRepondu
        })
        this.axios.get("/mariage/"+this.$weddingSlug).then((response) => {
            if(response.data.data.logement != null){
                if (response.data.data.logement.slug == this.$route.params.type) this.startQuiz()
            }
        })
        this.axios.get("/question/"+this.$route.params.type+"/"+this.$store.state.invitation.slug).then((response) => {
            this.questions = []
            for (let i=0;i<response.data.data.length;i++){
                this.questions.push(
                    {
                        question:response.data.data[i].libelle,
                        choosedResponse:response.data.data[i].reponses.map((v, i) => ({ i, v })).filter((r)=>  r.v.masReponse == true).map((r)=>r.i),
                        responses:response.data.data[i].reponses.map((r) => ({libelle:r.libelle,slug:r.slug})),
                        slug:response.data.data[i].slug,

                    }
                )
            }
        })
    }


}
</script>

<style>
    /* roll in */
    .roll-in-left-enter,
    .roll-in-left-leave-to{
        transform: translateX(-500px) rotate(-20deg);
        opacity: 0;
    }

    .roll-in-left-enter-active,
    .roll-in-left-leave-active{
        transition:  transform 0.3s, opacity 0.5s ;
    }
    /* fade up */
    .fade-y-enter,
    .fade-y-leave-to {
        opacity: 0;
        transform: translateY(20px);
    }

    .fade-y-enter-active,
    .fade-y-leave-active {
        transition: opacity 0.3s, transform 0.5s;
    }


    /* fade horizontally */

    .fade-x-enter,
    .fade-x-leave-to {
        opacity: 0;
        transform: translateX(20px);
    }

    .fade-x-enter-active,
    .fade-x-leave-to {
        transition: opacity 0.3s, transform 0.5s;
    }
</style>
