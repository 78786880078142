<template>

    <!-- start of wpo-site-footer-section -->
    <footer class="wpo-site-footer">
        <div class="wpo-lower-footer">
            <div class="container">
                <div class="row">
                    <div class="col col-xs-12">
                        <p class="copyright"> &copy;{{new Date().getFullYear()}} Tous les droits sont réservés| cette plateforme est la propriété de <a href="#" >New Start Agency  </a></p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer-component"
}
</script>

<style scoped>

</style>
