<template>
    <Header></Header>
    <div>
        <section class="wpo-contact-pg-section static-hero-s2 mac-top">
            <div class="container">
                <div class="row">
                    <div class="mac-flower-shape-1"><img src="/assets/images/slider/flower1.png" alt="">
                    </div>
                    <div class="mac-flower-shape-2"><img src="/assets/images/slider/flower2.png" alt="">
                    </div>
                </div>
                <div class="row d-flex justify-content-center align-items-center" v-if="showScan">
                    <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
                </div>
                <div class="row d-flex justify-content-center align-items-center" v-else>
                    <div class="s-icon text-center">
                        <i class="fi flaticon-serving-dish"></i>
                    </div>
                    <div class="text-center" style="font-size: 50px" v-if="loading">
                        <i class="fa fa-spinner fa-spin"></i>
                    </div>
                    <div v-else class="row justify-content-center">
                        <div v-for="(submenu,key) in menu" v-bind:key="key" class="col-12 col-md-6 col-lg-3 d-flex justify-content-center flex-column text-center  mt-4">
                            <h2>{{key}}</h2>
                            <ul class="text-center d-flex flex-column">
                                <li v-for="(plate,plateKey) in submenu" v-bind:key="plateKey">{{ plate.nom }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <Footer></Footer>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
import Footer from "./SubComponents/Footer";
import Header from "./SubComponents/Header";

export default {
    name: "MenuPage",
    components:{StreamBarcodeReader,Footer, Header},
    data:function () {
        return{
            showScan:true,
            qrText:"",
            loading:false,
            menu:'',
        }
    },
    methods:{
        onDecode(text) {
            var url = text.split("/");
            this.qrText = url[url.length-1]

            this.fetchData()
        },
        fetchData(){
            this.loading = true
            this.showScan = false
            this.axios.get("/menus/"+this.qrText)
                .then((response)=>{
                    const groupBy = (array, property) => array.reduce((grouped, element) => ({
                        ...grouped,
                        [element[property]]: [...(grouped[element[property]] || []), element]
                    }), {})
                    this.menu = groupBy(response.data?.data?.menu,"libelleGroupe")
                })
                .catch((error)=>{
                    if(error.response?.status == 500){
                        this.$store.commit("showPopup","Une erreur s'est produite veuillez réessayer plus tard")
                    }else{
                        this.$store.commit("showPopup",error.response?.data?.errors)
                    }
                    this.showScan = true
                }).finally(()=> {
                this.loading = false

            })
        },
        onLoaded() {
        },
    },
    mounted() {
        const ceremonieSlug = this.$route.params.ceremonie_slug
        this.qrText = ceremonieSlug
        if(ceremonieSlug != '') this.fetchData();
    }
}
</script>

<style scoped>


/*.v-enter-active,*/
/*.v-leave-active {*/
/*    transition: opacity 0.5s ease;*/
/*}*/

/*.v-enter-from,*/
/*.v-leave-to {*/
/*    opacity: 0;*/
/*}*/

/*.wrapper{*/
/*    display: inline-flex;*/
/*    background: transparent;*/
/*    height: 100px;*/
/*    !*width: 400px;*!*/
/*    align-items: center;*/
/*    justify-content: space-evenly;*/
/*    border-radius: 5px;*/
/*    padding: 20px 5px;*/
/*}*/
/*.mac-top{*/
/*    margin-top: 0px;*/
/*    max-height: 80vh !important;*/
/*}*/
/*@media (max-width: 992px) {*/
/*    .mac-top{*/
/*        margin-top: 85px;*/
/*        max-height: 76vh !important;*/

/*    }*/
/*}*/
.flaticon-serving-dish:before{
    font-size: 70px !important;
}
section{
    height: auto !important;
    padding-bottom: 100px !important;
    min-height: 100vh;
    padding-top: 150px;
}
li,ul{
    margin:0px !important;
    padding:0px !important;
}
</style>
