<template>
    <Header></Header>
    <!-- start of hero -->
<!--    <section class="static-hero-s3" style="padding: 150px 0px">-->
<!--        <div class="hero-container">-->
<!--            <div class="hero-inner">-->
<!--                <div class="container">-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </section>-->
    <!-- end of hero slider -->

    <section ref="billet" class="wpo-contact-section section-padding static-hero-s2 mac-margin-top" style="height: auto!important;">
        <div class="container">
            <div class="wpo-contact-section-wrapper">
                <div class="wpo-contact-form-area" :key="1" >
                    <div class="row justify-content-center">
                <div class="col-12">
                    <div class="wpo-event-item">
                        <div class="wpo-event-text p-0 p-sm-4" style="padding-bottom:0px;text-align:center">
                            <h1 v-if="bride != '' && groom != ''" style="font-family: 'Parisienne', cursive;font-size: 50px">{{bride}} & {{groom}}</h1>
                            <p v-if="bride != '' && groom != ''" style="color: #002642;font-size: 17px;padding:0px 20px;font-family: 'Parisienne'" class="mb-0">
                                {{invitation.sexe === "0" ? "M" : "Mme"}}<span style="font-family: 'Futura PT';text-align:justify">. {{invitation.nomComplet}}</span>, <br> {{invitation.texte_billet}}
                                <!--                                        {{bride}} & {{groom}} Ont le plaisir de vous convier à leur mariage qui se tiendra le {{ endDate.getUTCDate() }}, {{endDate.toLocaleString('fr-FR', { month: 'long' })}} {{endDate.getFullYear()}}-->
                            </p>
                            <h1 v-if="bride == '' && groom == ''" style="font-family: 'Parisienne', cursive;font-size: 50px"><i class="fa fa-spinner fa-spin"></i></h1>
                            statut de l'invitation : <span class="text-success">{{ invitation.etatInvitation }}</span>
                            <div>
                                <!--                                        <li><h4>Billet de : <span style="text-transform: uppercase">{{invitation.nomComplet}}</span></h4></li>-->
                                <div v-if="invitation.cavaliers != null && invitation.cavaliers.length !== 0">
                                    <b style="text-transform: uppercase">Cavalier(e)</b> :
                                    {{ invitation.cavaliers[0].nomComplet }}
                                    ({{invitation.cavaliers[0].etatInvitation}})
                                </div>
                                <div style="margin-bottom: 0px!important">  {{invitation.table == null ? "En attente d'attribution d'une table..." : "Vous serez à la "+invitation.table}} {{compagnons.length > 0?"; accompagné de: ":''}}</div>
                            </div>
                            <p v-if="invitation.table != null" style="overflow-wrap:break-word;padding: 0px 10px">
                                <span v-for="(compagnon,key) in compagnons" :key="key" style="flex:33.33%;padding:0px;text-align: center;margin-right:15px">{{compagnon.nomComplet+(key+1!=compagnons.length?', ':'')}}</span>
                            </p>
                            <img :src="this.$macBaseUrl+'/'+invitation.qr_code" alt="qr_code" class="qr_code" loading="lazy">
                        </div>
                    </div>
                </div>
            </div>
                </div>
                <div class="vector-1">
                    <img src="/assets/images/contact/1.png" alt="">
                </div>
                <div class="vector-2">
                    <img src="/assets/images/contact/2.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <Footer></Footer>
    <div @click="createImage" title="Telecharger mon billet" style="justify-content: center;align-items: center;display:flex;color:white;position: fixed;bottom: 20px;right: 20px;border:2px solid #002642;background-color:#002642;border-radius: 100px;padding:10px;z-index:999;cursor:pointer">
        <i v-if="converting" class="fa fa-spinner fa-spin me-2"></i>
        <span class="me-2 text-white d-md-block d-none">Télécharger mon billet</span>
        <i class="fa fa-download"></i>
    </div>

</template>

<script>
import Header from "./SubComponents/Header";
import Footer from "./SubComponents/Footer";
import jsPDF from 'jspdf'
export default {
    name: "Invitation-component",
    components: {Footer, Header},
    data:function (){
        return {
            invitation: {},
            groom:'',
            bride:'',
            endDate:new Date("2023-05-19 21:00:00"),
            slug_questionaire_logement:'',
            compagnons:'',
            texte_billet:'',
            converting:false,
            printAndQuit:this.$store.state.printAndQuit,


        }
    },
    methods:{
        redirectToLogementQuizz:function () {
            this.$router.push("/game/"+this.slug_questionaire_logement)
        },
        createImage:function () {
            this.converting = true

            this.axios.get("/billets/"+this.invitation?.slug,{responseType: 'blob'})
                .then(response => {
                    var blob = new Blob([response.data]);
                    // eslint-disable-next-line no-unused-vars
                    var image = new Image(blob);
                  var img = new Image()
                    img.src = window.URL.createObjectURL(response.data);
                    var doc = new jsPDF('l')
                    doc.addImage(img,17,20,270,170);
                    doc.save("Mariage de "+this.bride+" et de "+this.groom+" billet de "+this.invitation?.prenom+" "+this.invitation?.nom)
                    // const href = window.URL.createObjectURL(response.data);
                    //
                    // const anchorElement = document.createElement('a');
                    //
                    // anchorElement.href = href;
                    // anchorElement.download = "Mariage de "+this.bride+" et de "+this.groom+" billet de "+this.invitation?.prenom+" "+this.invitation?.nom;
                    //
                    // document.body.appendChild(anchorElement);
                    // anchorElement.click();
                    //
                    // document.body.removeChild(anchorElement);
                    // window.URL.revokeObjectURL(href);
                }).catch(()=>{
                    this.$store.commit("showPopup","Une erreur s'est produite veuillez réessayer plus tard")
                }).finally(()=> this.converting = false)

            // this.converting = true
            // const billet = this.$refs.billet;
            // domtoimage
            //     .toPng(billet)
            //     .then((dataUrl) => {
            //         this.createPDF(dataUrl)
            //     })
            //     .catch((error) => {
            //         this.$store.commit("showPopup","Une erreur s'est produite")
            //         this.converting = false
            //     });
        },
        createPDF:function (data) {
            let pdfName = 'Billet mariage '+this.bride+' et '+this.groom;
            var doc = new jsPDF('l');
            var img = new Image()
            img.src = data
            var paq = this.printAndQuit
            img.onload = function() {
                let height = img.height
                let width = img.width
                let fac = 1,left=0,top = 0;
                if (width > height){
                    fac = 300/width
                    height = height*fac
                    width = 300
                }else{
                    fac = 200/height
                    height = 200
                    width = width*fac
                }
                left = (300 - width )/2
                top = (210-height)/2
                doc.addImage(img, left, top, width,height)
                doc.save(pdfName + '.pdf');
                if (paq){
                    window.close();
                }
            }
            this.converting = false
        },
        showScanMenu:function () {
            this.$store.commit("toggleShowMenuPopUp",true)
        },
        ContinueSetingUp:function () {
          // this.invitation.slugTable="avocat"
          this.axios.get("/mariage/"+this.$weddingSlug).then((response) => {
            this.groom = response.data.data.nom_monsieur;
            this.bride = response.data.data.nom_madame;
            this.endDate = new Date(response.data.data.date);
          })
          let invitation = this.invitation
          this.axios.get("/tables/"+this.$weddingSlug).then((response) => {
            this.compagnons = response.data.data.find((t)=>t.slug == this.invitation.slugTable).invites.filter((c)=>c.slug != invitation.slug)
          })
          this.axios.get("/questionaire_logement/"+this.$weddingSlug).then((response) => {
            this.slug_questionaire_logement = response.data.data.slug
          })
          this.axios.get("/mariage/"+this.$weddingSlug).then((response) => {
            if(response.data.data.logement != null){
              this.slug_questionaire_logement = response.data.data.logement.slug
            }
          })
        }
    },
    watch: {
        // whenever question changes, this function will run
        slug_questionaire_logement(newVal, oldVal) {
            if (oldVal === '' && newVal !== '' && this.printAndQuit) {
                this.createImage()
            }
        }
    },
    mounted() {
      let slug = this.$route.params?.slug
      let print = (this.$route.params?.print?.length > 0)
      if (slug?.length > 0){
        this.$store.commit("setPrintAndQuit",print)
        this.printAndQuit = print
        this.axios.get("/detailInvite/"+slug).then((response) => {
          let result = response.data;
          if(result.error != null) {
            this.$store.commit("showPopup",result.error)
          }else{
            if (result.success != null) {
              this.$store.commit("showPopup", result.success)
              this.$store.commit("setInvitation",response.data.data)
              this.invitation = response.data.data
            }else{
              this.$store.commit("setInvitation",response.data.data)
              this.invitation = response.data.data
            }
            this.ContinueSetingUp()
          }
        }).catch(error => {
          if(error.response.status == 500){
            this.$store.commit("showPopup","Une erreur s'est produite veuillez réessayer plus tard")
          }else{
            this.$store.commit("showPopup",error.response.data.errors)
          }
        }).finally(() => this.sendingSlug = false)
      }else{
        this.invitation = this.$store.state.invitation
        this.ContinueSetingUp()
      }






    }
}
</script>

<style>
    .qr_code{
        width: 130px !important;
        /*float: left;*/
        /*margin-bottom: -100px;*/
    }
    @media (max-width: 450px) {
        .qr_code{
            width: 100px !important;
            float: left;
        }
    }


</style>
