<template>
    <!-- start preloader -->
<!--    <div class="preloader">-->
<!--        <div class="vertical-centered-box">-->
<!--            <div class="content">-->
<!--                <div class="loader-circle"></div>-->
<!--                <div class="loader-line-mask">-->
<!--                    <div class="loader-line"></div>-->
<!--                </div>-->
<!--                <img src="assets/images/favicon.png" alt="" loading="lazy">-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
    <!-- end preloader -->
    <!-- Start header -->
    <header id="header">
        <div class="wpo-site-header wpo-header-style-3">
            <nav class="navigation navbar navbar-expand-lg navbar-light">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                            <div class="mobail-menu">
                                MENU
                                <button type="button" class="mac-nav-toogler" @click="this.$store.commit('toggleSidebar')">
                                    <span class="sr-only">Toggle navigation</span>
                                    <span class="icon-bar first-angle"></span>
                                    <span class="icon-bar middle-angle"></span>
                                    <span class="icon-bar last-angle"></span>
                                </button>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6">
                            <div class="navbar-header">
                                <a class="navbar-brand" href="index.html">
                                    <!--                                    <img src="assets/images/logo-2.png" alt="" loading="lazy">-->
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-10 col-md-1 col-1">
                            <div id="navbar" :class="{collapse:true, navbar_collapse:true, navigation_holder:true, slideInn:showSideBar}">
                                <button class="menu-close" @click="this.$store.commit('toggleSidebar')"><i class="ti-close"></i></button>
                                <ul class="nav navbar-nav mb-2 mb-lg-0">
                                    <Menu></Menu>
                                </ul>

                            </div><!-- end of nav-collapse -->
                        </div>
                    </div>
                </div><!-- end of container -->
            </nav>
        </div>
    </header>
    <!-- end of header -->
</template>

<script>
import Menu from "./Menu";
export default {
    name: "Header-component",
    components: {Menu},
    computed:{
        showSideBar(){
            return this.$store.state.showSideBar;
        }
    }

}
</script>

<style scoped>

</style>
