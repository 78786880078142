<template>
    <div>
        <div class="popup" v-show="this.$store.state.showCagnottePopUp">
            <div class="popup-wrapper">
                <div class="card shadow px-3 py-2">
                    <div class="card-body text-center">
                        <h4 style="min-width: 200px" >Combien voulez-vous donner ?</h4>
                        <div class="row justify-content-center" style="margin: 20px 0px;max-width: 400px">
                            <div class="d-flex justify-content-center align-items-center">
                                <input type="number" class="form-control" name="email" id="email" style="font-size: 30px;width: 45%!important;height: 50px;text-align: center;color:#86a0b6" v-model="somme">
                                <span class="ms-2" style="font-weight: bold;color:#86a0b6 "  v-if="moyen == 'cart'">EUR</span>
                                <span class="ms-2" style="font-weight: bold;color:#86a0b6 " v-if="moyen != 'cart'">XAF</span>
                            </div>
                            <h4 style="min-width: 200px;margin-top: 20px" >Moyen de paiement</h4>
                            <div class="wrapper" style="display: flex">
                                <input type="radio" name="select" id="option-6" value="phone_money" v-model="moyen">
                                <input type="radio" name="select" id="option-7" value="cart" v-model="moyen">
                                <label for="option-6" class="option option-6">
                                    <div class="dot"></div>
                                    <div style="background-image: url('./assets/images/mobile-money.webp')" class="mc-mm"></div>
                                </label>
                                <label for="option-7" class="option option-7">
                                    <div class="dot"></div>
                                    <div style="background-image: url('./assets/images/card.png')" class="mc-mm"></div>
                                </label>
                            </div>
<!--                            <div>-->
<!--                                <div id="paypal"></div>-->
<!--                            </div>-->
                            <span>
                                <span v-if="moyen == 'cart'">* Vous serez prélever en EUR</span>
                                <span v-if="moyen != 'cart'">* Vous serez prélever en XAF</span>
                            </span>
                            <div class="submit-area d-flex justify-content-center mt-3">
                                <button type="submit" class="theme-btn-s4 d-flex align-items-center" @click="this.$store.commit('toggleCagnottePopUp')">
                                    Annuler !
                                </button>
                                <button type="submit" class="theme-btn-s4 d-flex align-items-center ms-3" @click="makeDonation">
                                    Valider
                                    <i v-if="processing" class="fa fa-spin fa-spinner ms-1"></i>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "cagnotePopUp",
    data:function () {
        return {
            somme:0,
            moyen:'cart',
            processing:false
        }
    },
    methods:{
        makeDonation:function () {
            if (this.somme > 0){
                this.processing = true
                this.axios.post("/cadeau",{
                    mode:this.moyen,
                    montant:this.somme,
                    slug_invitation:this.$store.state.invitation.slug
                }).then((response) => {
                    let result = response.data;
                    if(result?.errors != null) {
                        this.$store.commit("showPopup",result.errors)
                    }else{
                        window.location.href = result.payment_url
                    }
                }).catch(error => {
                    if(error.response?.status == 500){
                        this.$store.commit("showPopup","Une erreur s'est produite veuillez réessayer plus tard")
                    }else{
                        this.$store.commit("showPopup",error.response.data.errors)
                    }
                }).finally(() => this.processing = false)
            }
        }
    },

}
</script>

<style scoped>
.popup{
    position: fixed;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100Vw;
    height: 100vh;
    background: #cdcdcd70;
}

/* scalle in animation */
.scale-in-enter,
.scale-in-leave-to{
    transform: scale(0.88);
    opacity: 0;
}

.scale-in-enter-active,
.scale-in-leave-active{
    transition:  transform 0.3s, opacity 0.5s ;
}

/*.v-enter-active,*/
/*.v-leave-active {*/
/*    transition: opacity 0.5s ease;*/
/*}*/

/*.v-enter-from,*/
/*.v-leave-to {*/
/*    opacity: 0;*/
/*}*/

.wrapper{
    display: inline-flex;
    background: transparent;
    height: 100px;
    /*width: 400px;*/
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
    padding: 20px 5px;
}
.wrapper .option{
    background: transparent;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 10px;
    /*border-radius: 5px;*/
    cursor: pointer;
    padding: 0 10px;
    border: 2px solid lightgrey;
    transition: all 0.3s ease;
}
.wrapper .option .dot{
    height: 20px;
    width: 20px;
    background: #d9d9d9;
    border-radius: 50%;
    position: relative;
    margin-right: 5px;
}
.wrapper .option .dot::before{
    position: absolute;
    content: "";
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: #86a0b6;
    border-radius: 50%;
    opacity: 0;
    transform: scale(1.5);
    transition: all 0.3s ease;
}
input[type="radio"]{
    display: none;
}
#option-6:checked:checked ~ .option-6,
#option-7:checked:checked ~ .option-7{
    border-color: #86a0b6;
    /*background: #86a0b6;*/
}
#option-6:checked:checked ~ .option-6 .dot,
#option-7:checked:checked ~ .option-7 .dot{
    background: #fff;
}
#option-6:checked:checked ~ .option-6 .dot::before,
#option-7:checked:checked ~ .option-7 .dot::before{
    opacity: 1;
    transform: scale(1);
}
.wrapper .option span{
    font-size: 20px;
    color: #808080;
}
#option-6:checked:checked ~ .option-6 span,
#option-7:checked:checked ~ .option-7 span{
    color: #fff;
}
#option-3:checked:checked ~ .option-3{
    border-color: #86a0b6;
    /*background: #86a0b6;*/
}
#option-3:checked:checked ~ .option-3 .dot{
    background: #fff;
}
#option-3:checked:checked ~ .option-3 .dot::before{
    opacity: 1;
    transform: scale(1);
}
.wrapper .option span{
    font-size: 20px;
    color: #808080;
}
#option-3:checked:checked ~ .option-3 span{
    color: #fff;
}
.mc-mm{
    background-position: center;
    width: 110px;
    background-repeat: no-repeat;
    height: 50px;
    background-size: 100px;
}
.popup-wrapper{
    max-height: 100vh;
    overflow-y: auto;
}
</style>
