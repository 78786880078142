<template>
    <header id="header">
        <div class="wpo-site-header wpo-header-style-2">
            <nav class="navigation navbar navbar-expand-lg navbar-light original">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                            <div class="mobail-menu">
                                MENU
                                <button type="button" class="mac-nav-toogler" @click="this.$store.commit('toggleSidebar')">
                                    <span class="sr-only">TN</span>
                                    <span class="icon-bar first-angle"></span>
                                    <span class="icon-bar middle-angle"></span>
                                    <span class="icon-bar last-angle"></span>
                                </button>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6">
                            <div class="navbar-header">
                                <router-link to="/" class="navbar-brand"><img :src="'/assets/images/logo.png'" style="width: 75px" alt=""></router-link>
                            </div>
                        </div>
                        <div class="col-lg-10 col-md-1  col-1">
                            <div id="navbar" :class="{collapse:true, navbar_collapse:true ,navigation_holder:true,slideInn:this.$store.state.showSideBar}">
                                <button class="menu-close" @click="this.$store.commit('toggleSidebar')"><i class="ti-close"></i></button>
                                <ul class="nav navbar-nav mb-2 mb-lg-0">
                                    <Menu></Menu>
                                </ul>

                            </div><!-- end of nav-collapse -->
                        </div>

                    </div>
                </div><!-- end of container -->
            </nav>
        </div>
    </header>

</template>

<script>
import Menu from "./Menu";
export default {
    name: "Header-component",
    components: {Menu}

}
</script>

<style scoped>

</style>
