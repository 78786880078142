// require('./bootstrap');

import { createApp,h } from 'vue';
import App from './components/App.vue';
import router from "./routes/routes";
import vue3PhotoPreview from 'vue3-photo-preview';
import 'vue3-photo-preview/dist/index.css';
import Vuex from 'vuex';
import Store from './store/store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSilentbox from 'vue-silentbox'
import 'vue-silentbox/dist/style.css'





const app = createApp({
    render: ()=>h(App),
    router,
});

//********************CONFIGS*******************

//backend server url
axios.defaults.baseURL = 'https://panel.nice-planner.com';
app.config.globalProperties.$macBaseUrl = 'https://panel.nice-planner.com'
app.config.globalProperties.$weddingSlug = 'je-sais-pas'

//***********************************************

app.use(router)
app.use(vue3PhotoPreview)
app.use(Vuex)
app.use(Store)
app.use(VueAxios, axios)
app.use(VueSilentbox)
app.mount('#app');
