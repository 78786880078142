<template>
    <li @click="this.$store.commit('hideSidebar')">
        <router-link to="/" :class="this.$route.name == 'home' ?'active':''">Accueil</router-link>
    </li>
    <li @click="this.$store.commit('hideSidebar')">
        <router-link to="/gallerie" :class="this.$route.name == 'gallerie' ?'active':''">Galerie</router-link>
    </li>
    <li class="menu-item-has-children" v-if="questionnaires.length > 0">
        <a :class="this.$route.name == 'game' ?'active':''">Quizz</a>
        <ul class="sub-menu" style="">
            <li v-for="(questionnaire,key) in questionnaires" v-bind:key="key">
                <router-link @click="this.$store.commit('hideSidebar')" :to="'/game/'+questionnaire.slug" :class="this.$route.name == 'game' ?'active':''">{{questionnaire.nom}}</router-link>
            </li>
        </ul>
    </li>
    <li @click="this.$store.commit('hideSidebar')">
        <router-link to="/respond_to_invitation" :class="this.$route.name == 'respond_to_invitation' ?'active':''">serez-vous présent ?</router-link>
    </li>
    <li @click="this.$store.commit('hideSidebar')">
        <router-link to="/laisser_temoignage" :class="this.$route.name == 'laisser_temoignage' ?'active':''">Laisser un Témoignage</router-link>
    </li>
    <li @click="this.$store.commit('hideSidebar')">
        <router-link to="/invitation" :class="this.$route.name == 'invitation' ?'active':''">Mon billet</router-link>
    </li>
    <li @click="this.$store.commit('toggleCagnottePopUp')"  v-if="this.$store.state.cagnotte && this.$store.state.invitation != null">
        <a style="cursor: pointer" >Offrir un Cadeau</a>
    </li>
</template>

<script>
export default {
    name: "Menu-component",
    data:function(){
      return {
          questionnaires:'',
      }
    },
    mounted() {
        this.axios.get("/questionaires/"+this.$weddingSlug).then((response) => {
            this.questionnaires = response.data.data;
        })
    }
}
</script>

<style scoped>
  a{
    font-weight: bold!important;
  }
</style>
